<script>
  import Posts from "../../components/forum/Posts.svelte";
</script>

<div class="tabbed theme--solar-secondary">
  <section>
    <a href="#/forum/new" class="button">New Post</a>
    <Posts />
  </section>
</div>
