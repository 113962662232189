<script>
  import { slide } from "svelte/transition";
  import { space } from "../../store";
  import Welcome from "./Welcome.svelte";
  import Pad from "../../components/Pad.svelte";
  import Posts from "../../components/forum/Posts.svelte";

  let showIntro = true;
</script>

<style>
  .intro {
    background: linear-gradient(
      180deg,
      var(--color-a) 70%,
      var(--color-neutral) 100%
    );
    padding: var(--size-s) var(--size-xl) var(--size-xl) var(--size-xl);
  }
  @media screen and (max-width: 767px) {
    p {
      font-size: 1.2rem;
    }
  }
  .intro p {
    width: 100%;
    font-size: 2.3rem;
    line-height: 1.2;
  }
  .intro p u {
    text-decoration: none;
    border-image-source: linear-gradient(
      to right,
      rgba(var(--rgb-d), 0),
      rgba(var(--rgb-d), 0.5)
    );
    border-bottom: 10px solid;
    border-image-slice: 1;
    border-width: 2px;
  }
  .intro .collapse {
    cursor: pointer;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid var(--color-b);
    display: block;
    margin: 0 auto;
    transition: all 0.5s;
  }
  .intro .collapse.rotated {
    transform: rotateX(180deg);
  }
  .pad {
    max-width: 100%;
    padding: 0;
  }
  section {
    background-color: var(--color-neutral);
  }

  .forum {
    background-color: var(--color-neutral);
    padding: var(--size-xl) var(--size-s);
  }

  .forum section {
    border-radius: var(--border-radius);
    background: var(--color-a);
    color: var(--color-d);
    padding: 0;
  }

  .forum a {
    background: var(--color-a);
    color: var(--color-d);
  }
</style>

{#if $space === false}
  <Welcome />
{:else}
  <section class="intro">
    {#if showIntro}
      <p transition:slide|local>
        <u>postcoronialism</u> is an open collective, study, solidarity and strategy
        group that commonly works on/for the urgent spread of indisciplinary care,
        the demilitarisation of the health education and labor sectors and the ecological,
        systematic and agnotological comorbidity of Capital.
      </p>
    {/if}

    <span
      class="collapse"
      class:rotated={!showIntro}
      on:click={() => (showIntro = !showIntro)}
    />
  </section>

  <section class="pad">
    <Pad name={$space.name} key={$space.etherpadKey} />
  </section>

  <div class="forum tabbed">
    <div class="tabbed--buttons">
      <a href="#/forum/new" class="button">New Post</a>
    </div>
    <section class="tabbed--container">
      <Posts />
    </section>
  </div>
{/if}
