<script>
  import { push } from "svelte-spa-router";
  import PostForm from "../../components/forum/PostForm.svelte";

  function onSuccess(postId) {
    push("/forum/posts/" + postId);
  }
</script>

<section class="theme--solar-secondary">
  <h1>New post</h1>
  <PostForm {onSuccess} />
</section>
