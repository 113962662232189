<script>
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";
  import Markdown from "../Markdown.svelte";
  import { addPost, editPost } from "../../store";

  // What to do on success.
  export let onSuccess;

  export let onCancel = () => push("#/");
  // If `id` is set, then we are editing a post.
  export let id = null;

  // If `replyTo` is null, then we are creating a new thread, while if it's set
  // we are replying.
  export let replyTo = null;

  export let title = null;
  export let body = null;

  // This is the key where we store the draft. If we are creating a new post,
  // use `replyTo` as key, otherwise use the current `id`.
  const draftKey = `post-draft:${id === null ? replyTo : id}`;

  if (!title && !body) {
    let draft = loadDraft();
    title = draft.title;
    body = draft.body;
  }
  let error;
  let showPreview;
  let timerId;

  function saveDraft() {
    localStorage.setItem(draftKey, JSON.stringify({ title, body }));
  }

  function loadDraft() {
    let values;
    try {
      values = JSON.parse(localStorage.getItem(draftKey));
    } catch (e) {
      console.log(e);
    }
    values = values || {};
    return {
      title: values.title || "",
      body: values.body || "",
    };
  }

  function clearDraft() {
    localStorage.removeItem(draftKey);
  }

  onMount(() => {
    // Save the draft every second.
    timerId = setInterval(saveDraft, 1000);
    return () => clearInterval(timerId);
  });

  function clear() {
    title = "";
    body = "";
    clearInterval(timerId);
    clearDraft();
  }

  async function handleAddPost(replyTo, title, body) {
    try {
      const postId = await addPost(replyTo, title, body);
      if (onSuccess) {
        clear();
        onSuccess(postId);
      } else {
        clear();
        push("#/forum");
      }
    } catch (e) {
      error = e.message;
    }
  }

  async function handleEditPost(id, title, body) {
    try {
      const post = await editPost(id, title, body);
      if (onSuccess) {
        clear();
        onSuccess(post);
      } else {
        clear();
        push("#/forum");
      }
    } catch (e) {
      error = e.message;
    }
  }

  async function handleSubmit() {
    if (id === null) {
      handleAddPost(replyTo, title, body);
    } else {
      handleEditPost(id, title, body);
    }
  }

  /*
  function handleKeydown(e) {
    if ((e.ctrlKey || e.metaKey) && (e.keyCode == 13 || e.keyCode == 10)) {
      form.submit();
    }
  }
  */
</script>

<style>
  form {
    display: flex;
    flex-direction: column;
  }

  input[type="text"] {
    margin-bottom: var(--size-l);
  }

  input[type="text"],
  textarea {
    width: 100%;
  }

  textarea {
    min-height: 10rem;
  }

  p,
  label {
    color: var(--color-d);
    font-size: 0.9rem;
    font-family: "NotCourierSans";
  }

  input[type="checkbox"] {
    width: 1rem;
    height: 1rem;
  }

  .preview {
    color: var(--color-d);
    font-family: "NotCourierSans", monospace;
  }

  .commands {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
  }

  .commands button {
    margin: var(--size-m) 0;
    color: var(--color-a);
    background-color: var(--color-d);
  }

  .commands button:hover {
    background-color: var(--color-b);
  }

  .commands .back {
    color: var(--color-d);
    background-color: transparent;
    border: 1px solid transparent;
  }

  .commands .back:hover {
    color: var(--color-d);
    border: 1px solid var(--color-d);
    background-color: transparent;
    box-shadow: none;
  }
</style>

<form on:submit|preventDefault={handleSubmit} autocomplete="off">
  {#if !replyTo}
    <input
      autofocus
      type="text"
      placeholder="Title"
      required
      bind:value={title}
    />
  {/if}
  <textarea placeholder="Message" required bind:value={body} />
  {#if showPreview}
    <div class="preview">
      {#if body.length === 0}
        <p>Nothing to preview. Type something in the field above.</p>
      {/if}
      <Markdown text={body} />
    </div>
  {/if}
  <div class="commands">
    <p>
      Use
      <a
        href="https://guides.github.com/features/mastering-markdown/#syntax"
        target="_blank"
      >
        markdown</a
      >
      to format the message.
      <label
        >Show preview
        <input type="checkbox" bind:checked={showPreview} /></label
      >
    </p>
    <button class="alt" type="submit">Post</button>
    <button type="button" on:click={onCancel} class="back button alt"
      >Back</button
    >
  </div>
  {#if error}{error}{/if}
</form>
