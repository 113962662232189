<script>
  export let key;
  export let name;
</script>

<style>
  iframe {
    height: 100vh;
    padding: 0;
    margin: 0;
    border: 0;
  }
</style>

<iframe
  title="Collaborative pad"
  src="https://pad.riseup.net/p/{key}?userName={name}"
  width="100%"
/>
