<script>
  import { location } from "svelte-spa-router";
  import { inMeeting } from "../../store";

  import Mnemonic from "./Mnemonic.svelte";

  export let space;
  let showMagicWords;
  let error;

  $: home = $location === "/";
  // FIXME: there must be a better way!
  $: showHeader =
    !$location.startsWith("/join") && !$location.startsWith("/logout");

  function handleToggleMagicWords() {
    showMagicWords = !showMagicWords;
  }

  function onReset() {
    showMagicWords = false;
  }

  function onError(e) {
    error = e;
  }

  function handleLogout(e) {
    if ($inMeeting) {
      if (confirm("Leave the meeting?")) {
        $inMeeting = false;
      } else {
        e.preventDefault();
      }
    }
  }
</script>

<style>
  header {
    position: relative;
    height: 6rem;
    margin-bottom: var(--size-m);
  }
  .logo {
    text-decoration: none;
    width: 20rem;
    display: block;
  }
  .logo img {
    width: 100%;
  }
  .group {
    position: absolute;
    top: 0;
    right: 0;
    width: 14rem;
    margin: 0 0 0 auto;
    z-index: 10;
  }
  .group button,
  .group .button {
    width: 100%;
  }
  .mnemonic,
  .contact,
  .admin {
    text-transform: uppercase;
  }
  .mnemonic-container {
    margin-top: var(--size-m);
  }
  hr {
    height: 0.1rem;
    border: none;
    background: var(--color-d);
  }
</style>

{#if showHeader}
  <section>
    <header>
      {#if !home}
        <a class="logo" href="#" class:home>
          <img src="assets/images/banner.png" alt="postcoronialism" />
        </a>
      {/if}
      <div class="group">
        {#if space === false}
          <button class="naked mnemonic" on:click={handleToggleMagicWords}
            >Enter Magic&nbsp;Words</button
          >
          {#if showMagicWords}
            <div class="mnemonic-container">
              <Mnemonic {onReset} {onError} />
            </div>
          {:else}
            <hr />
            <a class="button naked contact" href="mailto:admin@example.org"
              >Make Contact</a
            >
          {/if}
        {:else}
          {#if space.isAdmin && home}
            <a class="button admin" href="#/admin">Admin</a>
            <hr />
          {/if}
          {#if !home}
            <a class="button" href="#">Back</a>
            <hr />
          {/if}
          <a on:click={handleLogout} class="button" href="#/logout"
            >Exit Space</a
          >
        {/if}
      </div>
    </header>
  </section>
{/if}
