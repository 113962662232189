<script>
  import { space, inMeeting } from "../store";
  import { location } from "svelte-spa-router";
  import Jitsi from "./jitsi";

  let width = "100%";
  let height = "600px";
  let detachedWidth = "250px";
  let detachedHeight = "150px";

  let detached;

  $: show =
    $space &&
    (["/", "/admin"].includes($location) || $location.startsWith("/forum"));
  $: {
    detached =
      detached || $location.startsWith("/forum") || $location === "/admin";
  }
  $: showPlaceholder = $location === "/";

  function handleJoin() {
    $inMeeting = true;
  }

  function onLeave() {
    $inMeeting = false;
  }
</script>

<style>
  section {
    margin-bottom: var(--size-l);
  }
  .placeholder {
    background-color: black;
    display: flex;
    padding: var(--size-xl);
    flex-direction: column;
    justify-content: space-evenly;
  }
  button.join {
    display: block;
    margin: 0 auto;
  }
</style>

{#if show}
  <section>
    {#if $inMeeting}
      <Jitsi
        name={$space.name}
        key={$space.jitsiKey}
        bind:detached
        {showPlaceholder}
        {width}
        {height}
        {detachedWidth}
        {detachedHeight}
        {onLeave}
      />
    {:else if showPlaceholder}
      <div class="placeholder rounded" style="width: {width}; height: {height}">
        <img
          class="logo"
          src="assets/images/banner.png"
          alt="postcoronialism"
        />
        <div>
          <button class="join" on:click={handleJoin}>Join meeting</button>
        </div>
      </div>
    {/if}
  </section>
{/if}
