<script>
  import { getPosts } from "../../store";
  import { prettyDate, toDateTime, toFullDate } from "./utils";

  let posts = getPosts(null, 1000, 0);
</script>

<style>
  ol {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: var(--color-d);
    font-size: 1.4rem;
  }

  li {
    list-style-type: none;
    display: flex;
    padding: 0 var(--size-l);
    align-items: baseline;

    border-image-source: linear-gradient(
      to right,
      rgba(var(--rgb-b), 0),
      rgba(var(--rgb-b), 0.5)
    );
    border-bottom: 10px solid;
    border-image-slice: 1;
    border-width: 1px;
  }

  li:last-child {
    border-bottom: none;
  }

  .title {
    position: relative;
    flex: 7;
  }

  .new-label,
  .name,
  .activity {
    font-family: "NotCourierSans", monospace;
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
  }

  .name {
    flex: 1;
  }

  .activity {
    flex: 2;
    text-align: right;
  }

  .no-posts {
    padding: var(--size-m);
    margin: 0;
  }
</style>

{#await posts then values}
  {#if values.length > 0}
    <ol>
      {#each values as post}
        <li class:new={!post.seen}>
          <p class="title">
            <a href="#/forum/posts/{post.id}"
              >{#if !post.seen}<span class="new-label">(New)</span>
              {/if}{post.title || "Untitled"}</a
            >
          </p>
          <p class="name">
            {post.name}
          </p>
          <p class="activity">
            <time
              title={toFullDate(post.lastTs * 1000)}
              datetime={toDateTime(post.lastTs * 1000)}
            >
              {prettyDate(post.lastTs * 1000)}
            </time>
          </p>
        </li>
      {/each}
    </ol>
  {:else}
    <p class="no-posts">No posts :(</p>
  {/if}
{:catch error}
  something bad happened
  {error}
{/await}
