<script>
  import { push } from "svelte-spa-router";
  import { prettyDate, toDateTime, toFullDate } from "./utils";
  import Markdown from "../Markdown.svelte";
  import {
    getPost,
    getPosts,
    deletePost,
    markPostAsSeen,
    publicKey,
  } from "../../store";
  import { onMount } from "svelte";
  import PostForm from "./PostForm.svelte";
  import Item from "./Item.svelte";
  export let id;

  let post, replies;

  setTimeout(() => {
    markPostAsSeen(id);
  }, 1000);

  onMount(() => {
    reload();
  });

  async function reload() {
    try {
      post = await getPost(id);
      replies = await getPosts(id, 1000, 0);
    } catch (e) {
      console.error(e);
      push("#/forum");
      return;
    }
  }

  function onReply(replyId) {
    reload();
  }

  async function handleDelete(id, root) {
    if (
      confirm(
        "Are you sure you want to delete this post? You can't undo this action."
      )
    ) {
      try {
        await deletePost(id);
        reload();
      } catch (e) {
        console.error(e);
      }
    }
  }

  async function handleEdit(id) {
    console.log("edit", id);
  }
</script>

<style>
  ol {
    margin: 0;
    padding: 0;
  }
  li {
    list-style-type: none;
    margin-bottom: var(--size-l);
  }
  .metadata {
    font-family: "NotCourierSans";
    font-size: 0.9rem;
    color: var(--color-d);
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    border-image-source: linear-gradient(
      to right,
      rgba(var(--rgb-b), 0),
      rgba(var(--rgb-b), 0.5)
    );
    border-bottom: 10px solid;
    border-image-slice: 1;
    border-width: 1px;
  }
  .metadata p {
    font-family: "NotCourierSans";
    font-size: 0.9rem;
    padding: var(--size-s) var(--size-m);
    margin: 0;
  }

  :global(.forum .body p) {
    line-height: 1.5;
  }

  .replies {
    margin-bottom: var(--size-xxl);
  }
</style>

{#if post}
  <Item {post} onUpdate={reload} />
  <div class="replies">
    {#if replies}
      <ol>
        {#each replies.reverse() as reply}
          <li>
            <Item post={reply} onUpdate={reload} />
          </li>
        {/each}
      </ol>
    {/if}
  </div>

  <div class="metadata">
    <p>Reply</p>
  </div>
  <PostForm replyTo={id} onSuccess={onReply} />
{/if}
