<script>
  import Audio from "./Audio.svelte";
</script>

<style>
  .logo {
    width: 100%;
    display: block;
  }

  .banner {
    max-width: 1536px;
  }

  p {
    color: var(--color-b);
    margin-left: 0;
    width: 60%;
    margin-left: 6rem;
    font-size: 2.6rem;
    line-height: 1.1;
  }

  @media screen and (max-width: 767px) {
    p {
      width: 100%;
      font-size: 1.2rem;
      margin-left: 0;
    }
  }
</style>

<section class="banner">
  <img class="logo" src="assets/images/banner.png" alt="postcoronialism" />

  <p>
    postcoronialism is an open collective, study, solidarity and strategy group
    that commonly works on/for the urgent spread of indisciplinary care, the
    demilitarisation of the health education and labor sectors and the
    ecological, systematic and agnotological comorbidity of Capital.
  </p>
</section>

<section class="audio">
  <Audio />
</section>
