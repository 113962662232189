<script>
  import { prettyDate, toDateTime, toFullDate } from "./utils";
  import { deletePost, publicKey } from "../../store";
  import Markdown from "../Markdown.svelte";
  import PostForm from "./PostForm.svelte";

  export let post;
  export let onUpdate;

  let editing = false;

  async function handleDelete(id, root) {
    if (
      confirm(
        "Are you sure you want to delete this post? You can't undo this action."
      )
    ) {
      try {
        await deletePost(id);
        onUpdate();
      } catch (e) {
        console.error(e);
      }
    }
  }

  async function handleEdit(id) {
    editing = true;
  }

  function handleCancel() {
    editing = false;
  }

  function handleSuccess() {
    editing = false;
    onUpdate();
  }
</script>

<style>
  h1 {
    font-family: "Sneaky Times";
    font-size: 2.4rem;
    font-weight: normal;
    text-transform: none;
    color: var(--color-d);
    padding: var(--size-m);
    margin-bottom: 0;
  }
  .metadata {
    font-family: "NotCourierSans";
    font-size: 0.9rem;
    color: var(--color-d);
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: var(--size-m);

    border-image-source: linear-gradient(
      to right,
      rgba(var(--rgb-b), 0),
      rgba(var(--rgb-b), 0.5)
    );
    border-bottom: 10px solid;
    border-image-slice: 1;
    border-width: 1px;
  }
  .metadata p,
  .metadata .controls {
    font-family: "NotCourierSans";
    font-size: 0.9rem;
    padding: var(--size-s) var(--size-m);
    margin: 0;
  }

  :global(.forum .body p) {
    line-height: 1.5;
  }

  .body {
    font-family: "NotCourierSans";
    padding: 0 var(--size-m) var(--size-m) var(--size-m);
    color: var(--color-d);
  }
</style>

{#if post.parentId === null}
  <h1>{post.title}</h1>
{/if}

<div class="metadata">
  <p>
    <strong>{post.name}</strong>
    commented
    <time
      title={toFullDate(post.ts * 1000)}
      datetime={toDateTime(post.ts * 1000)}
    >
      {prettyDate(post.ts * 1000)}
    </time>
  </p>
  {#if !editing && post.publicKey === $publicKey}
    <div class="controls">
      <button class="naked" on:click={() => handleEdit(post.id)}>Edit</button>
      <button
        class="naked"
        on:click={() => handleDelete(post.id, post.parentId === null)}
        >Delete</button
      >
    </div>
  {/if}
</div>
{#if !editing}
  <div class="body">
    <Markdown text={post.body} />
  </div>
{:else}
  <PostForm
    id={post.id}
    title={post.title}
    body={post.body}
    replyTo={post.parentId}
    onCancel={handleCancel}
    onSuccess={handleSuccess}
  />
{/if}
