<script>
  import Router, { location } from "svelte-spa-router";

  import { inMeeting, space } from "./store";

  import Header from "./components/header";
  import JitsiController from "./components/JitsiController.svelte";

  import Home from "./routes/home";
  import Forum from "./routes/forum/Index.svelte";
  import ForumGetPost from "./routes/forum/GetPost.svelte";
  import ForumAddPost from "./routes/forum/AddPost.svelte";
  import Admin from "./routes/admin";
  import Join from "./routes/join";
  import Logout from "./routes/logout";

  const routes = {
    "/": Home,
    "/forum": Forum,
    "/forum/new": ForumAddPost,
    "/forum/posts/:id": ForumGetPost,
    "/admin": Admin,
    "/logout": Logout,
    "/join/:invitation": Join,
  };

  $: {
    let path = $location.split("/")[1];
    let loggedIn = $space !== false;
    document.body.dataset.loggedIn = loggedIn;
    document.body.dataset.path = path;
    document.body.dataset.inMeeting = $inMeeting;

    if (
      ["admin", "join", "logout"].includes(path) ||
      (!loggedIn && path === "")
    ) {
      document.body.dataset.fullPage = "true";
    } else {
      document.body.dataset.fullPage = "false";
    }
  }
</script>

{#if $space !== undefined}
  <main>
    <Header space={$space} />
    <JitsiController />
    <Router {routes} />
  </main>
{/if}
