<script>
  import { onMount } from "svelte";
  import WaveSurfer from "wavesurfer.js";

  let waveform;
  let audioPlayer;
  let showPause;

  function onClick(e) {
    audioPlayer.playPause();
  }

  function onAudioPlay(e) {
    showPause = audioPlayer.isPlaying();
  }

  function onAudioPause(e) {
    showPause = audioPlayer.isPlaying();
  }

  function onAudioSeek(e) {
    if (audioPlayer.isPlaying()) {
      audioPlayer.play();
    }
  }

  onMount(() => {
    function onAudioReady() {
      audioPlayer = wavesurfer;
    }

    let wavesurfer = WaveSurfer.create({
      container: waveform,
      barWidth: 2,
      barHeight: 1,
      barGap: null,
      responsive: true,
      hideScrollbar: true,
      cursorWidth: 0,
      waveColor: "#f96400",
      progressColor: "#e1fe6e",
      normalize: true,
      height: 64,
    });
    wavesurfer.load("./assets/audio/welcome.mp3");
    wavesurfer.on("ready", onAudioReady);
    wavesurfer.on("play", onAudioPlay);
    wavesurfer.on("pause", onAudioPause);
    wavesurfer.on("seek", onAudioSeek);
    return () => {
      wavesurfer.un("ready", onAudioReady);
      wavesurfer.un("play", onAudioPlay);
      wavesurfer.un("pause", onAudioPause);
      wavesurfer.un("seek", onAudioSeek);
    };
  });
</script>

<style>
  img {
    width: 100%;
  }
  .container {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .controls {
    --size: 3rem;
    --ratio: 1;
    --color: var(--color-d);
    padding: 0 1rem;
    text-align: left;
    display: flex;
    align-items: center;
  }
  .controls button {
    width: calc(1.8 * var(--size));
    height: calc(1.8 * var(--size));
    border-color: transparent transparent transparent var(--color);
    border-width: var(--size) 0 var(--size) calc(var(--ratio) * var(--size));
    border-style: solid;
    padding: 0;
    background-color: transparent;
    transition: 100ms all ease;
    border-radius: unset;
    will-change: border-width;
  }
  .controls button:hover {
    filter: drop-shadow(0 0 var(--size-s) rgba(var(--rgb-b)));
    box-shadow: none;
  }
  .controls button.paused {
    border-style: double;
    border-width: 0 0 0 calc(var(--ratio) * var(--size));
  }
  .waveform {
    display: none;
    flex: 1;
  }
  @media screen and (max-width: 767px) {
    .controls {
      --size: 1rem;
      max-width: 20%;
    }
  }
</style>

<div class="container">
  <div class="controls">
    <button
      class:paused={showPause}
      on:click={onClick}
      disabled={!audioPlayer}
    />
  </div>
  <div class="monitor">
    <img src="assets/images/monitor.gif" alt="Heart rate monitor" />
  </div>
  <div id="waveform" class="waveform" bind:this={waveform} />
</div>
